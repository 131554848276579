<template>
  <v-container fluid class="grey lighten-3">

    <v-card>
      <v-card-title>
        {{ pageTitle }}
      </v-card-title>

      <v-card-text class="ml-4 pt-2">
        <h3>Negative keywords</h3>
        <p>Link automated negative lists to campaigns for this account.</p>
      </v-card-text>

      <v-card-actions class="pl-8 pt-0 pb-8">
        <v-btn color="primary" :loading="linking" @click="link()">Link negative lists</v-btn>

        <v-fade-transition mode="out-in">
          <v-btn color="green darken-1" text v-show="showCompleted">Completed</v-btn>
        </v-fade-transition>

      </v-card-actions>

    </v-card>

    <v-card>

      <v-card-actions class="pl-8 pt-8 pb-5">
        <v-btn class="mr-2" :to="{ name: 'Accounts' }">Back</v-btn>
      </v-card-actions>

    </v-card>

  </v-container>
</template>

<script>
import auth from '@/auth'
import formsMixin from '@/mixins/forms'

export default {
  name: 'AccountDetail',

  props: {
    id: { type: Number, required: true },
  },

  data () {
    return {
      item: {
        ad_network: null,
        account_name: null,
        account_id: null,
      },
      loading: false,
      linking: false,
      showCompleted: false,
    }
  },

  computed: {
    itemEndpoint () {
      return '/a/core/account-metadata/' + this.id
    },
    linkEndpoint () {
      if (!this.item.ad_network || !this.item.account_id) {
        return null
      }
      switch (this.item.ad_network) {
        case 'Google':
          return '/a/google/accounts/' + this.item.account_id + '/link-negative-lists'
        case 'Bing':
          return '/a/bing/accounts/' + this.item.account_id + '/link-negative-lists'
        default:
          return null
      }
    },
    pageTitle () {
      return this.item.account_name ? this.item.ad_network + ' - ' + this.item.account_name : 'Account'
    }
  },

  components: {
  },

  mixins: [formsMixin],

  methods: {
    link: function () {
      if (!this.linkEndpoint) {
        return
      }
      this.linking = true
      this.$http.put(this.linkEndpoint).then(resp => {
        this.showCompleted = true
        this.timer = setTimeout(() => { this.showCompleted = false }, 3000)
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.linkEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.linking = false)
    },

    fetchItem: function () {
      this.loading = true

      var myURL = this.itemEndpoint + '?xfields='
      // only get the fields needed to fill the item object
      myURL = this.forms_addItemFieldsToURI(myURL, this.item, [])

      this.$http.get(myURL).then(resp => {
        this.item = resp.data.data
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },
  },

  created: function () {
    this.fetchItem()
  },
}
</script>

<style scoped>
</style>